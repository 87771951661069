import React from 'react';
import "./programs.css"
import {programsData} from "../../data/programsData" 
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';

import { HashLink } from 'react-router-hash-link';

const Programs = () => {
    return (
        <div className='programs' id="programs">


            {/* header */}
            <div className='programs-header'>
                <span className='stroke-text'>Decouvrez nos</span>
                <span style={{ color: "aliceblue"}}>Programmes</span>
                <span className='stroke-text'>d'entrainement</span>
            </div>

            <div className='programs-categories'>
                {programsData.map((p, i) => (
                    <div className='category' key={i}>
                        {p.image}
                        <span>{p.heading}</span>
                        <span>{p.details}</span>
                        <HashLink smooth to="/#testimonials" >
                        
                        
                        <div className='join-now'>
                        <span>Rejoigner nous</span>
                        <ArrowForwardIcon style={{color: "aliceblue", fontSize: "2rem"}}/>
                        </div>
                        </HashLink>
                        
                    </div>
                ))}
            </div>
        </div>
    );
};

export default Programs;