import React from 'react';
import Hero from "../components/hero/Hero"
import "./home.css"


import Footer from '../components/footer/Footer';

import Join from '../components/join/Join';
import Plans from '../components/plans/Plans';
import Programs from '../components/programs/Programs';
import Reasons from '../components/reasons/Reasons';
import Testimanials from '../components/testimonials/Testimanials';


const Home = () => {
    return (
        <div className='titi'>
  
        <Hero />
        <Programs />
        <Reasons />
        <Plans />
        <Testimanials />
        <Join />
        <Footer />        </div>
    );
};

export default Home;