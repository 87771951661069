import image1 from "../assets/t-image1.webp";

import image3 from "../assets/t-image3.webp";
import image4 from "../assets/stock-photo.webp";

export const testimonialsData = [
  {
    image: image1,
    review:
      "J'ai fait le bon choix en choisissant le Fitclub et en choisissant le bon plan et programme j'ai déjà atteint mon corps idéal !",
    name: 'MATHEW HENDRICKSON',
    status : 'ENTREPRENEUR'
  },
  {
    image: image4,
    review:
    "J'ai fait le bon choix en choisissant le Fitclub et en choisissant le bon plan et programme j'ai déjà atteint mon corps idéal !",
    name: 'JESSE JANE',
    status: 'COACH'
  },
  {
    image : image3,
    review:
    "J'ai fait le bon choix en choisissant le Fitclub et en choisissant le bon plan et programme j'ai déjà atteint mon corps idéal !",
    name: 'FRANKLIN',
    status: "CLIENT"
  }
];
