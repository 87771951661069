import React, { useState} from 'react';
import "./header.css"
import Logo from "../../assets/logo.png"
import { HashLink } from 'react-router-hash-link';

const Header = () => {

    const [showLinks, setShowLinls] = useState(false)

    const handleLinks = () => {
        setShowLinls(!showLinks)
    }

      
    return (
        <div className='header'>
            <img className='logo' src={Logo} alt='Logo fitness' />
           
                <ul className={`header-menu ${showLinks ? "open" : "close"}`}   id="sidebar">
                    
                <li>
                <HashLink
                smooth
                to='/#programs'
               
                onClick={handleLinks}
                >Programmes
                </HashLink>
                </li>
                <li>
                    <HashLink
                    smooth    
                    to='/#reasons'
                       
                        onClick={handleLinks}
                    >Qui sommes nous
                    </HashLink>
                </li>
                    <li>
                        <HashLink
                        smooth
                        to='/#plans'
                       
                        onClick={handleLinks}
                        >Abonnements
                        </HashLink>
                    </li>
                    <li>
                        <HashLink
                        smooth
                            to='/#testimonials'
                           
                            onClick={handleLinks}
                        >Vos avis
                        </HashLink>
                    </li>


                    {/***/}
                
                {/***/}






                    </ul>
       
                    <div className='left' onClick={handleLinks}>
                    <div className={` burger ${showLinks ? "open" : "close"}`}  id="myID">
                    <div className='barre'></div>
                    </div>
                    </div>
                    </div>
                    );
                };
                
                export default Header;