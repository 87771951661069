import './App.css';
import { BrowserRouter, Routes, Route} from "react-router-dom"
import Home from './pages/Home';

import "react-toastify/dist/ReactToastify.css";
import { ToastContainer } from 'react-toastify'

function App() {
  return (
   <BrowserRouter>
   <ToastContainer 
   position="bottom-left"
   theme="colored"
   fontWeight="700"
 
   toastStyle={{ backgroundColor: "#f48915",  fontSize: "1.3rem" }}
 
 />
    <Routes>
      <Route path="*" element={<Home />} />
      <Route path="/" element={<Home />} />
    </Routes>
   </BrowserRouter>
  );
}

export default App;
